import React from "react";
import { MessageDto } from "../models/MessageDto";
import ReactMarkdown from "react-markdown";
import './Message.css'; // Make sure to import your CSS file

interface MessageProps {
  message: MessageDto;
  darkMode: boolean;
}

const Message: React.FC<MessageProps> = ({ message, darkMode }) => {
  return (
    <div
      className={`message ${message.isUser ? "user" : "bulby"}`}
    >
      <div
        className={`message-bubble ${message.isUser ? "user" : "bulby"} ${darkMode ? "dark" : ""}`}
      >
        <ReactMarkdown
          className="message-content"
          components={{
            a: ({ node, ...props }) => (
              <a {...props} target="_blank" rel="noopener noreferrer">
                {props.children}
              </a>
            ),
          }}
        >
          {message.content}
        </ReactMarkdown>
      </div>
    </div>
  );
};

export default Message;